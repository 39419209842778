import React from "react"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import Socials from "../../Socials/Socials"

import "./Intro.scss"

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { regex: "/me/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const avatar = data.file.childImageSharp.fluid

  return (
    <section className="section">
      <Img
        fluid={avatar}
        className="section__avatar"
        alt="Piotr Kawula's Photo"
      />
      <article className="section__article">
        <h2 className="section__article--heading">Let me introduce myself</h2>
        <p className="section__article--description">
          I am a highly experienced front-end developer with 4 years of
          programming expertise, including 2 and a half years in a professional
          capacity. I am currently serving as a Lead Front-end Developer in two
          projects, where I am responsible for maintaining the projects and
          planning future development.
          <br />
          <br />I have a deep understanding of React, NextJS, and TypeScript,
          and I feel confident using JavaScript and exploring other libraries
          and frameworks. Additionally, I have experience with Vue 3 and Angular
          10+, as well as with Docker, CI/CD, and front-end testing (unit and
          end-to-end).
        </p>
        <p>
          Check out my{" "}
          <Link to="/skills" className="section__article--link">
            skills
          </Link>{" "}
          and{" "}
          <Link to="/projects" className="section__article--link">
            projects
          </Link>
          . I'm also looking for new challenges and if you have one - feel free
          to{" "}
          <Link to="/contact" className="section__article--link">
            contact
          </Link>{" "}
          me!
        </p>
        <a
          href="mailto:kontakt@piotrkawula.pl"
          className="section__article--link"
        >
          kontakt@piotrkawula.pl
        </a>
      </article>
      <Socials />
    </section>
  )
}

export default Intro
