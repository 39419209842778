import React from 'react';

import GithubIcon from '../../assets/icons/github.svg';
import LinkedinIcon from '../../assets/icons/linkedin.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';

import './Socials.scss';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

const Socials = () => (
    <aside className="socials" aria-label="Social Icons">
        <ButtonIcon href="https://github.com/pkawula/" aria-label="Github">
            <GithubIcon className="socials__link--icon" />
        </ButtonIcon>
        <ButtonIcon href="https://www.linkedin.com/in/piotr-kawula-8bab8b164/" aria-label="Linkedin">
            <LinkedinIcon className="socials__link--icon" />
        </ButtonIcon>
        <ButtonIcon href="https://www.instagram.com/ojciecpio_xd/" aria-label="Instagram">
            <InstagramIcon className="socials__link--icon" />
        </ButtonIcon>
    </aside>
);

export default Socials;