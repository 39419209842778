import React from 'react';
import Layout from '../components/layout';
import Intro from '../components/sections/Intro/Intro';

const IndexPage = () => (
    <Layout pageTitle='Piotr |{awula'>
        <Intro />
    </Layout>
);

export default IndexPage;